hr {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-top: 1px solid #d1d4d7;
}

.caret {
  transition-duration: 0.2s;
}

.caret-active {
  transform: rotate(-180deg);
}

.pointer {
  cursor: pointer;
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.w100 {
  width: 100% !important;
}

.m-auto {
  margin: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}
