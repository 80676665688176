tbody .k-master-row {
  cursor: pointer;
}

.k-textbox,
.k-input {
  min-width: 2.5rem !important;
  background: #fff !important;
  padding: 0 !important;
}

.k-numerictextbox .k-input {
  text-align: right;
}

.k-widget.k-combobox,
.k-widget.k-multiselect,
.k-widget.k-datapicker {
  width: 100%;
}

.k-multiselect-wrap.k-floatwrap {
  background: #fff !important;
}

.k-textbox-container::after {
  background-color: transparent;
}

.k-pager-numbers > li > a {
  height: 1.5rem;
  width: 1.5rem;
}

.k-grid tr:not(.k-grid-edit-row) td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.k-grid th,
.k-grid td {
  padding: 8px 12px;
}

.k-widget.k-grid {
  border-radius: 8px;
  overflow: hidden;
}

.k-grid .k-filtercell-operator {
  display: none;
}

.k-grid .k-textbox {
  line-height: 2;
}

.k-grid .k-grid-edit-row td:not(.k-hierarchy-cell),
.k-grid .k-edit-cell {
  padding: 8px !important;
}

.k-grid-edit-row .k-widget,
.k-grid-edit-row .k-textbox {
  background-color: transparent !important;
}

.k-grid-edit-row > td > .k-textbox {
  margin: 0 !important;
}

.k-grid .k-grid-content-sticky,
.k-grid-header th.k-grid-header-sticky {
  border-right: 0 !important;
}

.k-grid-content.k-virtual-content {
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    box-sizing: border-box;
    border-radius: 8px;
    border: 2px solid #fff;
    background: #1d2939;
  }
}

.k-grid-header th .k-filtercell {
  .k-dropdown,
  .k-combobox {
    font-weight: 400;
    font-size: 0.875rem;
  }
}

.k-grid-footer {
  padding: 0 !important;
}

.k-master-row.k-state-selected {
  background-color: #d1d4d7 !important;
}

.k-animation-container {
  z-index: 10000 !important;
}
