@import './layout.scss';

/* Flexbox
// ================================================== */
.flex {
  display: flex;
}
.flexinline {
  display: inline-flex;
}

/* Flex direction */
.flexrow {
  flex-direction: row;
} /* Default */
.flexrow-rev {
  flex-direction: row-reverse;
}
.flexcol {
  flex-direction: column;
}
.flexcol-rev {
  flex-direction: column-reverse;
}

/* Flex wrap */
.fw-nowrap {
  flex-wrap: nowrap;
} /* Default */
.fw-wrap {
  flex-wrap: wrap;
}
.fw-wr {
  flex-wrap: wrap-reverse;
}

/* Justify content */
.jc-end {
  justify-content: flex-end;
}
.jc-start {
  justify-content: flex-start;
} /* Default */
.jc-center {
  justify-content: center;
}
.jc-sa {
  justify-content: space-around;
}
.jc-sb {
  justify-content: space-between;
}

/* Align items */
.align-start {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.align-stretch {
  align-items: stretch;
} /* Default */
.align-baseline {
  align-items: baseline;
}

/* Flex grow */
.fg-0 {
  flex-grow: 0;
} /* Default */
.fg-1 {
  flex-grow: 1;
}

/* Flex shrink */
.fs-0 {
  flex-shrink: 0;
}
.fs-1 {
  flex-shrink: 1;
} /* Default */

/* Flex basis */
.fb-a {
  flex-basis: auto;
} /* Default */
.fb-0 {
  flex-basis: 0;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

@media (min-width: $xsmall) {
}

@media (min-width: $small) {
}

@media (min-width: $medium) {
}

@media (min-width: $large) {
  .flexcol-lg {
    flex-direction: column;
  }
}

@media (min-width: $xlarge) {
}
